<template>
  <f-container topBottom>
    <lms-card>
      <lms-card-title>
        <template #icon>
          <v-icon>mdi-table-of-contents</v-icon>
        </template>

        {{ $t('labels.trainingTypes') }}
      </lms-card-title>

      <v-data-table
        hide-default-footer
        :items-per-page="-1"
        class="elevation-0"
        :headers="headers"
        :items="items"
      >
        <template v-slot:[`item.name`]="{ item }">
          {{ item.name }}
        </template>

        <template
          v-slot:[`item.actions`]="{ item }"
          v-if="$store.state.system.superAdmin"
        >
          <lms-data-table-btn @click="edit(item.id)">
            <v-icon>mdi-pencil</v-icon>
          </lms-data-table-btn>
          <lms-data-table-btn @click="remove(item.id)">
            <v-icon>mdi-delete</v-icon>
          </lms-data-table-btn>
        </template>
      </v-data-table>
    </lms-card>
  </f-container>
</template>

<script>
export default {
  computed: {
    items() {
      return this.$store.state.setting.userCategory.list;
    },
    headers() {
      return [
        {
          text: this.$tt.capitalize(this.$t('reports.student.name')),
          align: 'start',
          value: 'title',
          sortable: false,
        },
        {
          text: this.$tt.capitalize(this.$t('labels.actions')),
          align: 'end',
          value: 'actions',
          sortable: false,
        },
      ];
    },
  },
  created() {
    this.get();
  },
  methods: {
    get() {
      this.$store.dispatch('setting/listUserCategory');
    },
    edit(id) {
      this.$router.push({
        name: 'settings-user-category-edit',
        params: { id },
      });
    },
    async remove(id) {
      var response = await this.$store.dispatch('dialog/show', {
        title: this.$t('alerts.courses.removeUserCategory.title'),
        content: this.$t('alerts.courses.removeUserCategory.content'),
      });
      if (response) {
        this.$store.dispatch('setting/removeUserCategory', id);
      }
    },
  },
};
</script>

<template>
  <div>
    <f-page-header :title="title" :prevLinks="prevLinks">
      <template #actions v-if="$store.state.system.superAdmin">
        <lms-page-header-action-btn icon="mdi-plus" @click="create"
          >create</lms-page-header-action-btn
        >
      </template>
    </f-page-header>

    <f-category-list />
  </div>
</template>

<script>
import FCategoryList from '../../../components/views/settings/data/UserCategoryList.vue';

export default {
  components: {
    FCategoryList,
  },
  computed: {
    prevLinks() {
      return [
        {
          title: this.$t('labels.settings'),
          route: {
            name: 'settings',
          },
        },
      ];
    },
    title() {
      return this.$t('labels.userCategory');
    },
  },
  methods: {
    create() {
      this.$router.push({
        name: 'settings-user-category-create',
      });
    },
  },
};
</script>
